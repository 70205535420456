import { graphql } from 'gatsby';
import React from 'react';

import { PageLayout } from '../components/layout/page';

export const query = graphql`
  query PageHomeQuery($id: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageHome(id: { eq: $id }) {
      model {
        apiKey
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        ...ModuleTextAndImage
        ...ModuleText
        ...ModuleImage
        ...ModuleHeading
        ...ModuleGallery
        ...ModuleExpandingList
        ...ModuleHero
        ...ModuleNumberedList
        ...ModuleQuote
        ...ModuleValuesList
        ...ModulePositionsList
        ...ModuleDownloadList
        ...ModuleLatestNews
        ...ModuleLogoBlock
        ...ModuleIntro
      }
    }
    versions: allDatoCmsPageHome {
      nodes {
        locale
        model {
          apiKey
        }
      }
    }
  }
`;

const PageHome = ({ data, pageContext }: any) => {
  return <PageLayout data={data} pageContext={pageContext} />;
};

export default PageHome;
